import React from 'react';
import { PageProps } from 'gatsby';
import Container from '@/components/common/container';
import Layout from '@/components/common/layout';
import { useStaticQuery, graphql } from 'gatsby';
import CtaLink from '@/components/common/cta-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '@/components/seo';

const NotFound: React.FC<PageProps> = () => {
  const query = useStaticQuery(graphql`
    {
      sanityNotFound {
        title
        subtitle
        image {
          asset {
            gatsbyImageData
          }
        }
        ctaLink {
          label
          slug {
            current
          }
        }
      }
    }
  `);

  const { title, subtitle, image, ctaLink } = query.sanityNotFound;

  return (
    <Layout>
      <Container className="py-16 md:py-20">
        <div className="flex flex-col gap-12 text-center">
          <GatsbyImage
            alt=""
            className="mx-auto w-full md:max-w-[540px]"
            image={image.asset.gatsbyImageData}
          />

          <div className="flex flex-col items-center">
            <h1 className="text-[30px] leading-tight md:text-[40px]">
              {title}
            </h1>
            <p className="font-outfit mt-4 text-lg font-light leading-normal md:max-w-[500px]">
              {subtitle}
            </p>

            <CtaLink
              bg="white"
              to=""
              label={ctaLink.label}
              className="mx-auto mt-6 max-w-[200px]"
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFound;

export const Head = () => (
  <SEO
    title="404 - Page not found"
    description="The page you are looking for does not exist."
  />
);
